/**
 * The settings set here only apply to the browser platform
 * On the android platform only the settings from files/xpedeo/local/xpedeo.json are used
 *
 * With window.XPEDEO_SETTINGS you can set all initial settings
 * For the structure see @xpedeo/core/plugins/settings/default-settings.js
 * For testing purposes with browser you have to set the "remoteUrl"
 */

window.XPEDEO_SETTINGS = {
  content: {
    // remoteUrl: 'http://stadtmuseum-muenster-xpedeo-2018/'
    // remoteUrl: 'http://xpedeo-live.ueberseemuseum.vpn/'
    // remoteUrl: 'http://xpedeo-android-2016/'
    // remoteUrl: 'http://stadtmuseum-muenster.xpedeo.de/'
    // remoteUrl: 'http://stadtmuseum-muenster.xpedeo.de/'
    // remoteUrl: 'http://lwl-industriemuseum-xpedeo-2019/'
    // remoteUrl: ''
    remoteUrl: (process.env.NODE_ENV !== 'development' && (process.env.VUE_APP_IS_LOAN_DEVICE || process.env.VUE_APP_PLATFORM === 'browser')) ? '' : 'http://hm-frankfurt-2021.xpedeo.de/',
    useStaticPageFiles: false
  },
  theme: 't-clear-inverse',
  showLocationSettingsWarning: true
}

/**
 * The following variable should be moved into the settings.
 */
// window.BLUETOOTH_NAVIGATOR_TYPE = 'locale'
